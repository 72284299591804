import { memo, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { useMount } from "hooks";
import { getOpenWeatherForecasts, getWeatherCurrent } from "services";
import CardForecast from "./CardForecast";

const WeatherForecasts = ({ lat, lon, t }) => {
  const sizeIcons = 64;

  const [time, setTime] = useState("00:00:00");
  const date = moment().format("DD MMM YYYY");
  const [forecasts, setForecasts] = useState([]);
  const [weather, setWeather] = useState({
    weather: [{ main: "", icon: "02d" }],
  });

  useEffect(() => {
    setInterval(() => {
      setTime(moment().format("HH:mm:ss"));
    }, 1000);
  }, []);

  const fetchDataDefault = async () => {
    const results = await getOpenWeatherForecasts(lat, lon);
    const weatherCurrent = await getWeatherCurrent(lat, lon);

    setForecasts(results?.list);
    setWeather(weatherCurrent);
  };

  useMount(() => void fetchDataDefault());

  return (
    <div className="card-weathers p-3">
      <Row>
        <Col lg={12}>
          <h1>{t("weather_station")}</h1>
          <p>
            We are providing highly recognisable weather products that make
            working with the weather data a way easier.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <div className="card-icon p-3 text-center">
            <img
              src={`https://openweathermap.org/img/wn/${weather?.weather[0]?.icon}@2x.png`}
            />
          </div>
        </Col>
        <Col lg={5}>
          <h5 className="mb-0">CURRENT WEATHER</h5>
          <h1 className="mb-0">
            <b className="text-weather">{weather?.weather[0]?.main}</b>
          </h1>
          <h1>
            {`${parseFloat(weather?.main?.temp * 0.1).toFixed(2)} °C` ??
              "0.00 °C"}
          </h1>
          <p className="mb-0">
            <b>HUMIDITY {`${weather?.main?.humidity} %`}</b>
          </p>
          <p className="mb-0">
            <b>WIND SPEED {`${weather?.wind?.speed} km`}</b>
          </p>
        </Col>
        <Col lg={4}>
          <h5 className="mb-0">{date}</h5>
          <p className="mb-0">
            <b className="text-weather">{time}</b>
          </p>
          <h5 className="mt-3">
            {weather?.sys?.country} | {weather?.name}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mt-3">
          <h4>{t("forecasts")}</h4>
        </Col>
        <Col lg={12} className="mb-3">
          <CardForecast initialData={forecasts} />
        </Col>
      </Row>
    </div>
  );
};

export default memo(WeatherForecasts);
