import { memo } from "react";
import { Row, Col, Button } from "react-bootstrap";

// Images
import SolarLoofTopImg from "assets/images/defaults/SolarLoof.png";

const RectangleComponents = ({ t }) => {
  return (
    <div
      className="card-rectangles p-3"
      style={{
        backgroundImage: `url(${SolarLoofTopImg})`,
      }}
    >
      <Row>
        <Col className="title-card">
          <h1>{t("ultimate_goal")}</h1>
          <p style={{ width: "70%", fontSize: 18 }}>
            {" "}
            The vision of our company is to be the Ultimate Energy Solutions
            Provider. To achieve our vision, we put our resources into two core
            businesses; Clean Energy Innovation and Clean Energy Generation.
          </p>
          <div>
            <Button variant="outline-primary" style={{ width: 120 }}>
              Readmore
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default memo(RectangleComponents);
