import { memo, useState } from "react";
import { Row, Col, Button, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faUpload } from "@fortawesome/free-solid-svg-icons";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import AreaChart from "components/Visualizations/AreaChart";
import CardPayments from "components/CardPayments";

import { paymentMethods } from "services";

const TicketLists = ({ onUpload, initialData, onRefresh, discount, t }) => {
  const [show, setShow] = useState(false);
  const reciveDataToPayments = async (value) => {
    const datas = {
      ...value,
      projectId: initialData?.id,
      plantId: initialData?.plantId,
    };

    const { success } = await paymentMethods(datas);

    if (success) {
      Swal.fire({
        title: t("completed") + " !",
        html: "Your send payment method is successfully.",
        icon: "success",
      });
      onRefresh();
      setShow(false);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="card bor-dark-gray mb-2">
      <Row>
        <Col lg={5} xs={12} className="mb-3">
          <h4 className="mb-0">
            <Link to={`/projects/${initialData?.id}`}>
              {initialData?.project}
            </Link>
          </h4>
          {initialData?.finStatus === "WAITING" &&
            initialData?.documentType === "QUA" && (
              <div>
                <p className="mb-0 text-description">
                  {t("waiting_quotaion_from_saller")}
                </p>
              </div>
            )}

          {initialData?.finStatus === "WAITING" &&
            initialData?.documentType === "WQT" && (
              <div>
                <p className="mb-0 text-description">
                  {t("please_upload_file_purchase_orders")}
                </p>
              </div>
            )}

          {initialData?.finStatus === "PROCESS" &&
            initialData?.documentType === "WPO" && (
              <div>
                <p className="mb-0 text-warning">
                  {t(
                    "file_purchase-order_from_you_on_process_payment_wait_approved"
                  )}
                </p>
              </div>
            )}

          {initialData?.finStatus === "PROCESS" &&
            initialData?.documentType === "INV" && (
              <div>
                <p className="mb-0 text-info">
                  {t("please_upload_payment_slip")}
                </p>
              </div>
            )}

          {initialData?.finStatus === "PROCESS" &&
            initialData?.documentType === "WSL" && (
              <div>
                <p className="mb-0 text-secondary">
                  {t("wait_received_invoice_from_finance")}
                </p>
              </div>
            )}

          {!initialData?.finStatus &&
            !initialData?.documentType &&
            initialData?.max > 0 && (
              <div>
                <p className="mb-0 text-secondary">
                  {t("please_payment_for_preview_data_detections")}
                </p>
              </div>
            )}
        </Col>
        <Col xs={12} lg={3} className="mb-3">
          <h3 className="mb-0">{commaNumber(initialData?.max)}</h3>
        </Col>
        <Col xs={6} lg={2} className="text-end">
          {initialData?.documentType === "INV" && (
            <>
              <Button
                className="ms-2 mt-1"
                variant="outline-info"
                size="sm"
                onClick={() => onUpload(initialData, "INV")}
              >
                <FontAwesomeIcon icon={faUpload} /> {t("upload_slip")}
              </Button>
            </>
          )}
        </Col>
        <Col className="text-end" xs={6} lg={2}>
          {(!initialData?.finStatus ||
            initialData?.documentType === "INV" ||
            initialData?.documentType === "WSL") &&
            initialData?.max > 0 && (
              <div>
                <div className="cursor-ab">
                  <div className="triangle-right bounce2"></div>
                  <div className="text-small title-list">{t("click")}</div>
                </div>

                <Button
                  className="me-3 mt-1 title-list"
                  variant="outline-warning"
                  size="sm"
                  onClick={() => handleShow()}
                >
                  <FontAwesomeIcon icon={faWallet} /> {` `}
                  {t("payment")}
                </Button>
              </div>
            )}

          {initialData?.documentType === "WQT" &&
            initialData?.paymentType &&
            initialData?.price > 0 && (
              <>
                <Button
                  className="me-2 mt-1"
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => onUpload(initialData, "QUA")}
                >
                  <FontAwesomeIcon icon={faUpload} /> {` `} {t("upload_po")}
                </Button>
              </>
            )}
        </Col>
      </Row>
      <Offcanvas
        className="offcanvas-customs offcanvas-size-xl"
        show={show}
        onHide={handleClose}
        placement="end"
        size="lg"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h1 className="header-page">Payment Solutions</h1>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg={12} xs={12}>
              <h1 className="header-page">Prediction Loss</h1>
              <p className="text-warning">
                The prediction of the system based on monthly calculations can
                be displayed in the following graphs.
              </p>
              <AreaChart />
              <h1 className="header-page mb-0">Payment Methods</h1>
              <p className="text-info">
                Select the item you want to use for payment. The following
                payment options are available: Installment payments or all
                items.
              </p>
              <CardPayments
                valueMax={initialData?.max}
                valueMin={initialData?.min}
                onFinish={reciveDataToPayments}
                discount={discount}
              />
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default memo(TicketLists);
