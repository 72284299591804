import { memo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VerticleBarChart = () => {
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Chart.js Horizontal Bar Chart",
      },
    },
    borderRadius: 10,
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
        ],

        backgroundColor: "#0bdcd9",
      },
      {
        label: "Dataset 2",
        data: [
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
        ],
        backgroundColor: "#ea1212",
      },
    ],
  };

  return (
    <Card className="p-3 mb-3 dark-gray">
      <Bar options={options} data={data} />
    </Card>
  );
};

export default memo(VerticleBarChart);
