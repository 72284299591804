import { memo } from "react";
import { Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="footer-section">
      <div className="text-center text-footer">
        {dayjs().format("YYYY")} © Develop by Enserv Power
      </div>
    </section>
  );
};

export default memo(Footer);
