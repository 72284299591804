import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Container } from "react-bootstrap";

//:: Import Page Components :://
import NotFound from "components/NotFound";
import Dashboards from "pages/Dashboards";
import Tickets from "pages/Tickets";
import PageLayouts from "pages/PageLayouts";
import Projects from "pages/Projects";

const MainLayouts = ({ translates }) => {
  return (
    <div>
      <Header t={translates} />
      <Container className="bg-main py-3">
        <Routes>
          <Route exact path="/" element={<Tickets t={translates} />} />
          <Route
            exact
            path="/projects/:id"
            element={<Projects t={translates} />}
          />
          <Route
            exact
            path="/dashboard"
            element={<Dashboards t={translates} />}
          />
          <Route path="/layouts" element={<PageLayouts />} />
          <Route exact path="/*" element={<NotFound />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  );
};

export default MainLayouts;
