import api from "../services/api";

export const PAYMENTS_URL = "/payments";
export const FINANCE_UPLOAD_URL = "/finances/uploads";

export const paymentMethods = async (datas) => {
  return api.post(`${PAYMENTS_URL}`, datas).then((res) => res.data);
};

export const financeUploadFiles = async (data) => {
  return api
    .post(`${FINANCE_UPLOAD_URL}/pre-orders`, data)
    .then((res) => res.data);
};

export const financeUploadSlips = async (data) => {
  return api.post(`${FINANCE_UPLOAD_URL}/slips`, data).then((res) => res.data);
};
