import { memo, useState, useEffect } from "react";
import { Row, Col, Carousel } from "react-bootstrap";

const CardForecast = ({ initialData = [] }) => {
  const [datas, setDatas] = useState([]);

  const convertDatas = () => {
    let newDatas = [];
    const dataA = [];
    let counter = 0;
    let countLast = 0;

    initialData?.map((wt, key) => {
      if (counter < 4) {
        newDatas.push({ ...wt });
        counter++;
        countLast++;
        if (countLast === initialData?.length) {
          dataA.push(newDatas);
        }
      } else {
        dataA.push(newDatas);
        counter = 0;
        newDatas = [];
        newDatas.push({ ...wt });
        counter++;
        countLast++;
      }
    });

    setDatas(dataA);
  };

  useEffect(() => {
    convertDatas();
  }, [initialData]);

  return (
    <div>
      <Carousel>
        {datas?.map((res, key) => {
          return (
            <Carousel.Item key={key}>
              <Row>
                {res.map((wt, k) => {
                  return (
                    <Col lg={3} sm={6} xs={6} key={k} className="mb-3">
                      <div className="card-forecast-weathers p-3 text-center">
                        <img
                          src={`https://openweathermap.org/img/wn/${wt?.weather[0]?.icon}@2x.png`}
                        />
                        <p className="mb-0">{wt?.dt_txt}</p>
                        <h1 className="mb-0">{wt?.weather[0]?.main}</h1>
                        <p>{wt?.weather[0]?.description}</p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default memo(CardForecast);
