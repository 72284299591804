import { memo } from "react";
import GoogleMapReact from "google-map-react";

const GoogleMaps = ({ pins, location }) => {
  return (
    <>
      <div style={{ height: "25vh", width: "100%", borderRadius: "5px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${``}`,
          }}
          center={{ lat: 12.828951, lng: 99.909952 }}
          zoom={15}
          options={{}}
        ></GoogleMapReact>
      </div>
    </>
  );
};

const AnyReactComponent = ({ pinname, type }) => (
  <>
    <div className="ms-3" style={{ width: "220px" }}>
      <b>{pinname}</b>
    </div>
    <div
      className={`pin-map ${type === "CG" ? "" : "pin-danger danger"} bounce`}
    />
    <div className={`pulse ${type === "CG" ? "" : "danger"}`} />
  </>
);

export default memo(GoogleMaps);
