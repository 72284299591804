import { memo, useState } from "react";
import { Row, Col, Image, Modal, Form, Button } from "react-bootstrap";
import { useMount } from "hooks";
import Swal from "sweetalert2";
import Select from 'react-select'
import { BankLists, TypePayments } from "../../const";
import { DatePicker } from "antd";
import moment from "moment";
import {
  getProjects,
  financeUploadFiles,
  financeUploadSlips,
  getPlantAccounts,
} from "services";

import Breadcrumbs from "components/Breadcrumbs";
import CardHeaders from "components/CardHeaders";
import GoogleMaps from "components/GoogleMaps";
import TicketLists from "components/TicketLists";
import HeaderTitle from "components/TicketLists/HeaderTitle";

import LogoTest from "assets/images/logos/EQS_logoB8.png";

import { faBoltLightning, faCoins } from "@fortawesome/free-solid-svg-icons";

const Tickets = ({ t }) => {
  const breadcrumbs = [
    {
      lable: t("dashboard"),
      path: "/dashboard",
      active: false,
    },
    {
      lable: t("tickets"),
      active: true,
    },
  ];
  const [tickets, setTickets] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [totalMax, setTotalMax] = useState(0);
  const [typeUpload, setTypeUpload] = useState(undefined);
  const [discount, setDiscount] = useState(0);
  const [powerLoss, setPowerLoss] = useState(0)

  // Variable for payments Invoice
  const [paymentType, setPaymentType] = useState(undefined)
  const [bank, setBank] = useState(undefined)
  const [branch, setBranch] = useState(undefined)
  const [cheque, setCheque] = useState(undefined)
  const [datePayment, setDatePayment] = useState(undefined)

  const getAccountPlant = async (token) => {
    const { data: results } = await getProjects(token);

    setTickets(results?.tickets);
    let totalSummary = 0;
    let totalPower = 0;
    results?.tickets?.map((rs) => {
      totalSummary = totalSummary += rs?.max;
      totalPower = totalPower += rs?.power
    });

    setPowerLoss(totalPower)

    setTotalMax(totalSummary);
  };

  const fetchDataDefault = async () => {
    try {
      const { data } = await getPlantAccounts();

      getAccountPlant(data?.slug);
      setDiscount(data?.discount);
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  /** Upload file */
  const [dataDoc, setDataDoc] = useState({});
  const showModalUploads = (value, type) => {
    setTypeUpload(type);
    setDataDoc(value);
    setShowUpload(true);
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const MAX_COUNT = 5;

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleEventUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const onSubmitUploadFile = async (e) => {
    let dataSets = {}
    if (typeUpload === 'INV') {

      dataSets = {
        ...dataDoc,
        payment: paymentType,
        bank,
        branch,
        cheque,
        datePayment
      }
    }
    

    const formDatas = new FormData();
    if (uploadedFiles.length > 0) {
      uploadedFiles.map((fi) => {
        formDatas.append("files", fi);
      });

      formDatas.append("payloads", JSON.stringify({...dataDoc, ...dataSets}));
      if (typeUpload === "QUA") {
        await financeUploadFiles(formDatas);
        Swal.fire({
          title: "Completed !",
          html: "Upload Purchase Order file is successfully.",
          icon: "success",
        });

        fetchDataDefault();
      } else if (typeUpload === "INV") {
        await financeUploadSlips(formDatas);

        Swal.fire({
          title: "Completed !",
          html: "Upload Slip file is successfully.",
          icon: "success",
        });

        fetchDataDefault();
      }

      setShowUpload(false);
      setUploadedFiles([]);
      fetchDataDefault();
    } else {
      return false;
    }
  };
  /** Upload file */

  const handleTypePayment = (event) => {
    setPaymentType(event?.value)
  }
  const handleSetDate = (event) => {
    setDatePayment(moment(event).format('YYYY-MM-DD'))
  }

  const handleBank = (event) => {
    setBank(event?.value)
  }

  const handleBranch = (event) => {
    setBranch(event?.target.value)
  }

  const handleCheque = (event) => {
    setCheque(event?.target?.value)
  }

  return (
    <section className="ticket-page">
      <Breadcrumbs lists={breadcrumbs} />
      <h1 className="header-page mb-3">{t("tickets")}</h1>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <div className=" flex-item-center my-3">
                <Image className="logo-banner" src={LogoTest} alt={`Logo`} />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <CardHeaders
                title={t("energy_loss")}
                icons={faBoltLightning}
                value={powerLoss}
                unit={`kWh`}
                color={`yellow`}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <CardHeaders
                title={t("money_loss")}
                icons={faCoins}
                value={totalMax.toFixed(2)}
                unit={`฿`}
                color={`red`}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={12} className="mb-2">
                  <div className="card dark-gray p-1">
                    <GoogleMaps />
                  </div>
                </Col>
                <Col sm={12}>
                  <HeaderTitle t={t} />
                </Col>
                <Col sm={12} className="mb-3 scroller-y">
                  {tickets?.map((res, key) => {
                    return (
                      <TicketLists
                        key={key}
                        status={res?.finStatus}
                        initialData={res}
                        discount={discount ?? 0}
                        onRefresh={fetchDataDefault}
                        onUpload={showModalUploads}
                        t={t}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal show={showUpload} onHide={() => setShowUpload(false)} size="xl">
        <Modal.Header closeButton className="dark-theme p-1 px-3">
          <Modal.Title>Upload Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="uploaded-files-list mb-3">
            <p className="text-warning">File name</p>
            {uploadedFiles.map((file) => (
              <div className="text-white">{file.name}</div>
            ))}
          </div>
          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Control
              type="file"
              multiple
              accept="application/pdf, image/png, image/jpg"
              onChange={handleEventUpload}
              disabled={fileLimit}
            />
            <Form.Label className="text-white mt-2">
              Upload file for confirm orders
            </Form.Label>
            {
              typeUpload === "INV" &&
              <div>
                <Row>
                  <Col xs={6} className="mb-3">
                  <p className="text-warning">{t('payment_type')}</p>
                  <Select options={TypePayments} onChange={handleTypePayment}/>
                  </Col>
                </Row>
                {
                  paymentType === 'CASH' &&
                  <Row>
                    <Col xs={12} lg={6} className="mb-3">
                      <p className="text-warning">{t('date_payment')}</p>
                      <DatePicker className="w-100" onChange={handleSetDate}/>
                    </Col>
                  </Row>
                }

                {
                  paymentType === 'CHEQUE' &&
                  <Row>
                    <Col xs={12} lg={6} className="mb-3">
                      <p className="text-warning">{t('cheque_number')}</p>
                      <Form.Control
                        placeholder="Cheque Number"
                        name="cheque_number"
                        onChange={handleCheque}
                      />
                    </Col>
                    <Col xs={12} lg={6} className="mb-3">
                      <p className="text-warning">{t('date_payment')}</p>
                      <DatePicker className="w-100" onChange={handleSetDate} name="date_payment"/>
                    </Col>
                    <Col xs={12} lg={6} className="mb-3">
                      <p className="text-warning">{t('bank')}</p>
                      <Select options={BankLists} onChange={handleBank}/>
                    </Col>
                    <Col xs={12} lg={6} className="mb-3">
                      <p className="text-warning">{t('branch')}</p>
                      <Form.Control
                        placeholder="Branch : 0001"
                        name="branch"
                        onChange={handleBranch}
                      />
                    </Col>
                  </Row>
                }
                
              </div>
            }

          </Form.Group>
          <div className="text-end">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={onSubmitUploadFile}
            >
              Upload
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default memo(Tickets);
