export const BankLists = [
    { label: "ธนาคารกรุงไทย", value: "KTB" },
    { label: "ธนาคารกรุงเทพ", value: "BBL" },
    { label: "ธนาคารกรุงศรีอยุธยา", value: "BAY" },
    { label: "ธนาคารกสิกรไทย", value: "KBANK" },
    { label: "ธนาคารทิสโก้", value: "TISCO" },
    { label: "ธนาคารเกียรตินาคินภัทร", value: "KKP" },
    { label: "ธนาคารซีไอเอ็มบี ไทย", value: "CIMBT" },
    { label: "ธนาคารทหารไทยธนชาต", value: "TTB" },
    { label: "ธนาคารไทยพาณิชย์", value: "SCB" },
    { label: "ธนาคารยูโอบี", value: "UOB" },
    { label: "ธนาคารแลนด์ แอนด์ เฮ้าส์", value: "LH" },
    { label: "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)", value: "SC" },
    { label: "ธนาคารไอซีบีซี (ไทย)", value: "ICBC" },
    { label: "ธนาคารไทยเครดิต เพื่อรายย่อย", value: "TCRB" },
    { label: "ธนาคารเมกะ สากลพาณิชย์", value: "MEGA" },
    { label: "ธนาคารแห่งประเทศจีน (ไทย)", value: "BOC" },
    { label: "ธนาคารเจพีมอร์แกน เชส", value: "JPMCB" },
    { label: "ธนาคารซิตี้แบงก์ เอ็น.เอ.", value: "CITI" },
    { label: "ธนาคารซูมิโตโม มิตซุย แบงกิ้ง คอร์ปอเรชั่น", value: "SMBC" },
    { label: "ธนาคารดอยซ์แบงก์", value: "DBBK" },
    { label: "ธนาคารบีเอ็นพี พารีบาส์", value: "BNPP" },
    { label: "ธนาคารมิซูโฮ จำกัด สาขากรุงเทพฯ", value: "MIZUHO" },
    { label: "ธนาคารแห่งอเมริกาเนชั่นแนลแอสโซซิเอชั่น", value: "BofA" },
    { label: "ธนาคารอาร์ เอช บี", value: "RHB" },
    { label: "ธนาคารอินเดียนโอเวอร์ซีส์", value: "Indian" },
    { label: "ธนาคารโอเวอร์ซี-ไชนีสแบงกิ้งคอร์ปอเรชั่น", value: "OCBC" },
    { label: "ธนาคารฮ่องกงและเซี่ยงไฮ้แบงกิ้งคอร์ปอเรชั่น", value: "HSBC" },
    { label: "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย", value: "SME" },
    { label: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", value: "BAAC" },
    { label: "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย", value: "EXIM" },
    { label: "ธนาคารออมสิน", value: "GSB" },
    { label: "ธนาคารอาคารสงเคราะห์", value: "GHB" },
    { label: "ธนาคารอิสลามแห่งประเทศไทย", value: "ISBT" },
  ];

  export const TypePayments = [
    { label: "Cash", value: "CASH" },
    { label: "Cheque", value: "CHEQUE" },
  ]