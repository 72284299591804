import { memo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const AreaChart = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Daily Loss",
      },
    },
    borderJoinStyle: "round",
    borderWidth: 2,
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Loss",
        data: [
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
          Math.random(0, 100) * 100000,
        ],
        borderColor: "#ffc107",
        backgroundColor: "#ffc10740",
        tension: 0.5,
      },
    ],
  };
  return (
    <Card className="dark-gray mb-3 p-3">
      <Line options={options} data={data} />
    </Card>
  );
};

export default memo(AreaChart);
