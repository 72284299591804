import { memo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const MultiAreaChart = ({ title, desc }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        Line: true,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          with: 10,
          borderWidth: 0,
        },
      },
      title: {
        display: false,
      },
    },
    borderJoinStyle: "round",
    borderWidth: 2,
    responsive: true,
    height: 320,
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Dataset 1",
        data: [
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
        ],
        borderColor: "#f9c10a",
        backgroundColor: "#f9c10a40",
        tension: 0.5,
      },
      {
        fill: true,
        label: "Dataset 2",
        data: [
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
        ],
        borderColor: "#0eec72",
        backgroundColor: "#0eec7240",
        tension: 0.5,
      },
      {
        fill: true,
        label: "Dataset 1",
        data: [
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
          Math.random(0, 100) * 100,
        ],
        borderColor: "#ea1212",
        backgroundColor: "#ea121240",
        tension: 0.5,
      },
    ],
  };
  return (
    <Card className="dark-gray mb-3 p-3">
      <h1>{title ?? ""}</h1>
      <p>{desc ?? ""}</p>
      <Line options={options} data={data} />
    </Card>
  );
};

export default memo(MultiAreaChart);
