import { memo } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import commaNumber from "comma-number";

const CardHeaders = ({ icons, title, value, unit, color }) => {
  const sizeIcons = 28;
  return (
    <Row>
      <Col xs={12} className="mb-3">
        <div className="card header-icon dark-gray text-center pt-4">
          <div className="mb-1">
            <FontAwesomeIcon
              className={`icons ${color}`}
              style={{ fontSize: sizeIcons }}
              icon={icons ?? faQuestion}
            />
            <h3 className="mb-1">{title}</h3>
          </div>
          <div className="header"></div>
          <div className="description">
            <h1 className={`value-unit`}>
              <b className={`${color}`}>{commaNumber(value)}</b> {unit}
            </h1>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default memo(CardHeaders);
