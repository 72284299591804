import { memo } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Camera,
  Tool,
  Power,
  Activity,
  BarChart,
  Cloud,
  Calendar,
} from "react-feather";

import CardComponents from "components/Cards";
import VerticleBarChart from "components/Visualizations/VerticleBarChart";
import HorizontalBarChart from "components/Visualizations/HorizontalBarChart";
import AreaChart from "components/Visualizations/AreaChart";
import MultiAreaChart from "components/Visualizations/MultiAreaChart";
import DoughnutChart from "components/Visualizations/DoughnutChart";
import AntGuage from "components/Visualizations/AntGuage";

const PageLayouts = () => {
  const data = [
    {
      projectName: "project",
      location: "cha-am",
      irr: 154,
      temp: 27,
      pr: 128,
      power: 54,
      createAt: "2022.01.07",
      status: true,
    },
    {
      projectName: "project",
      location: "cha-am",
      irr: 127,
      temp: 54,
      pr: 133,
      power: 32,
      createAt: "2022.01.07",
      status: false,
    },
    {
      projectName: "project",
      location: "cha-am",
      irr: 128,
      temp: 32,
      pr: 121,
      power: 42,
      createAt: "2022.01.07",
      status: false,
    },
    {
      projectName: "project",
      location: "cha-am",
      irr: 111,
      temp: 33,
      pr: 132,
      power: 45,
      createAt: "2022.01.07",
      status: true,
    },
    {
      projectName: "project",
      location: "cha-am",
      irr: 130,
      temp: 36,
      pr: 124,
      power: 51,
      createAt: "2022.01.07",
      status: true,
    },
  ];
  return (
    <div>
      <h3 className="mb-3">Card Components</h3>
      <Row>
        <Col lg={6}>
          <Row>
            <Col xl={6} lg={6} xs={12}>
              <CardComponents
                icon={<Camera className="bd-icon green" size={62} />}
                title={"CAMERA"}
                desc={"Hello World"}
                value={"111"}
                unit={"kWh"}
                color={"green"}
              />
            </Col>
            <Col xl={6} lg={6} xs={12}>
              <CardComponents
                icon={<Tool className="bd-icon pink" size={62} />}
                title={"TOOLS"}
                desc={"Hello World"}
                value={"222"}
                unit={"Ohm"}
                color={"pink"}
              />
            </Col>
            <Col xl={6} lg={6} xs={12}>
              <CardComponents
                icon={<Power className="bd-icon yellow" size={62} />}
                title={"POWER"}
                desc={"Hello World"}
                value={"333"}
                unit={"V"}
                color={"yellow"}
              />
            </Col>
            <Col xl={6} lg={6} xs={12}>
              <CardComponents
                icon={<Activity className="bd-icon violet" size={62} />}
                title={"ACTIVITY"}
                desc={"Hello World"}
                value={"444"}
                unit={"A"}
                color={"violet"}
              />
            </Col>
            <Col xl={6} lg={6} xs={12}>
              <CardComponents
                icon={<BarChart className="bd-icon orange" size={62} />}
                title={"CHART"}
                desc={"Hello World"}
                value={"555"}
                unit={"Mw"}
                color={"orange"}
              />
            </Col>
            <Col xl={6} lg={6} xs={12}>
              <CardComponents
                icon={<Cloud className="bd-icon blue" size={62} />}
                title={"CLOUD"}
                desc={"Hello World"}
                value={"666"}
                unit={"kVAR"}
                color={"blue"}
              />
            </Col>
            <Col xl={6} lg={6} xs={12}>
              <CardComponents
                icon={<Calendar className="bd-icon red" size={62} />}
                title={"CALENDAR"}
                desc={"Hello World"}
                value={"30"}
                unit={"Days"}
                color={"red"}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={12} sm={12}>
              <VerticleBarChart />
            </Col>
            <Col lg={12} sm={12}>
              <HorizontalBarChart />
            </Col>
            <Col lg={12} sm={12}>
              <AreaChart />
            </Col>
            <Col lg={12} sm={12}>
              <MultiAreaChart />
            </Col>
            <Col lg={12} sm={12}>
              <DoughnutChart />
            </Col>
            <Col lg={6} sm={6}>
              <AntGuage />
            </Col>
            <Col lg={6} sm={6}>
              <AntGuage />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default memo(PageLayouts);
