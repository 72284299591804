import { memo, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useMount } from "hooks";
// Components.
import Breadcrumbs from "components/Breadcrumbs";
import RectangleComponents from "components/Shapes/Rectangles";
import WelcomeComponent from "components/Welcomes";
import MultiAreaChart from "components/Visualizations/MultiAreaChart";
import WeatherForecasts from "components/WeatherForecasts";

import { getUserInfo, getPlantAccounts } from "services";

// import image icon

const Dashboards = ({ t }) => {
  const [userInfo, setUserInfo] = useState({});
  const [plant, setPlant] = useState({});
  const desc = `This is a comprehensive introduction to the essential concepts and
  methodologies needed for valuations of assets and power generators.
  Unlike generalized valuation programs, you will be focused exclusively
  on the power sector and learn from an accomplished practitioner with
  over 25 years of experience valuing hundreds of plants and utilities.`;
  const breadcrumbs = [
    {
      lable: t("dashboard"),
      active: true,
    },
  ];

  const fetchDataDefault = async () => {
    try {
      const data = await getUserInfo();
      const { data: resultPlant } = await getPlantAccounts();
      setPlant(resultPlant);
      setUserInfo(data);
    } catch (error) {}
  };

  useMount(() => void fetchDataDefault());

  useEffect(() => {}, [plant]);

  return (
    <section className="main-project">
      <Breadcrumbs lists={breadcrumbs} />
      <h1 className="header-page">{t("dashboard")}</h1>
      <Row>
        <Col lg={5} className="mb-3">
          <Row>
            <Col lg={12} className="mb-3">
              <WelcomeComponent data={userInfo} plant={plant} t={t} />
            </Col>
            <Col lg={12}>
              <MultiAreaChart title={t("summarys")} desc={`${desc}`} t={t} />
            </Col>
          </Row>
        </Col>
        <Col lg={7}>
          <Row>
            <Col lg={12} className="mb-3">
              <RectangleComponents t={t} />
            </Col>
            <Col lg={12} className="mb-3">
              {plant?.latitude && (
                <WeatherForecasts
                  lat={plant?.latitude}
                  lon={plant?.lonitude}
                  t={t}
                />
              )}
            </Col>
          </Row>
        </Col>
        {/* <Col xl={3} lg={3} md={6} sm={12} className="mb-3"></Col> */}
      </Row>
    </section>
  );
};

export default memo(Dashboards);
