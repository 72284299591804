import { memo } from "react";
import { Row, Col } from "react-bootstrap";

const ListMaxs = ({ data }) => {
  return (
    <div>
      <Row>
        {data?.map((res, k) => {
          return (
            <Col
              lg={12}
              xs={12}
              className="p-1 px-3 card mb-1"
              key={k}>
              <Row>
                <Col xs={2}>{(k += 1)}.</Col>
                <Col xs={3}>{res?.stringName}</Col>
                <Col xs={1}></Col>
                <Col xs={3}>{res?.p}</Col>
                <Col xs={3}>{res?.pr}</Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default memo(ListMaxs);
