import { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMount } from "hooks";
import { getDetectionTickets } from "services";
import { Row, Col, Button, Offcanvas } from "react-bootstrap";
import { BarChart, FileText } from "react-feather";
import commaNumber from "comma-number";
import moment from "moment";
import { CSVLink } from "react-csv";
import Breadcrumbs from "components/Breadcrumbs";
import SolarMaps from "../../components/SolarMaps";
import Detections from "../../components/Detections";
import ListMaxs from "../../components/ListMaxs";

export const StatusCvert = (value) => {
  console.log(value);
  if (value === "D") {
    return "ALERT";
  } else if (value === "W") {
    return "WARNING";
  } else if (value === "N") {
    return "NORMAL";
  } else {
    return "Unknow";
  }
};

const Projects = ({ t }) => {
  const { id } = useParams();
  const [tickets, setTickets] = useState([]);
  const [maxStrings, setMaxStrings] = useState([]);
  const [canvasDisplay, setCanvasDisplay] = useState(false);

  const [csvDownloads, setCsvDownloads] = useState([
    [
      "STATUS",
      "STRING NAME",
      "LOSS VALUE",
      "POWER",
      "PR",
      "IRR",
      "AMB TEMP",
      "PV TEMP",
      "DATE STAMP",
    ],
  ]);

  const breadcrumbs = [
    {
      lable: t("dashboard"),
      path: "/dashboard",
      active: false,
    },
    {
      lable: t("tickets"),
      path: "/",
      active: false,
    },
    {
      lable: t("detections"),
      active: true,
    },
  ];

  const fetchDataDefault = async () => {
    try {
      const { data } = await getDetectionTickets(id);
      setTickets(data?.tickets ?? []);
      setMaxStrings(data?.maxs ?? []);

      const csvDatas = [];

      data?.tickets?.map((res) => {
        csvDatas.push([
          StatusCvert(res?.ticketStatus),
          res?.stringName,
          `${commaNumber(parseFloat(res?.valueLoss).toFixed(2) ?? 0)}`,
          res?.p,
          res?.pr,
          res?.irr,
          res?.ambTemp,
          res?.pvTemp,
          moment(res?.stampDate).format("DD/MM/YYYY"),
        ]);
      });

      setCsvDownloads([...csvDownloads, ...csvDatas]);
    } catch (error) {}
  };

  const handleClose = () => {
    setCanvasDisplay(false);
  };

  const handleShowCanvas = () => {
    setCanvasDisplay(true);
  };

  useMount(() => void fetchDataDefault());

  return (
    <section className="project-page">
      <Breadcrumbs lists={breadcrumbs} />
      <Row>
        <Col>
          <h1 className="header-page mb-3">{t("detections")}</h1>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Button
            variant="outline-warning"
            size="sm"
            className="me-2 px-3">
            <FileText
              size={18}
              className="me-2"
            />
            <CSVLink
              data={csvDownloads}
              filename={`${moment().format("DDMMYYYYHHmmss")}.csv`}>
              {t("document")}
            </CSVLink>
          </Button>

          <Button
            variant="outline-success"
            size="sm"
            className="px-3"
            onClick={handleShowCanvas}>
            <BarChart
              size={18}
              className="me-2"
            />
            {t("max")}
          </Button>
        </Col>
      </Row>
      <SolarMaps />
      <Detections data={tickets} />
      <Offcanvas
        show={canvasDisplay}
        onHide={handleClose}
        className="theme-dark">
        <Offcanvas.Header className="offcv-custom">
          <Offcanvas.Title>{t("display_string_max")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcvbd-custom">
          <ListMaxs data={maxStrings} />
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
};

export default memo(Projects);
