import { memo } from "react";
import { Row, Col, Button } from "react-bootstrap";

const HeaderTitle = ({ t }) => {
  return (
    <div className="card dark-gray-2 mb-2 ticket-header">
      <Row>
        <Col xs={5} className="title-list">
          <b>{t("project_name")}</b>
        </Col>
        <Col xs={4} className="title-list">
          <b>{t("detection_loss")}</b>
        </Col>
        <Col className="text-end title-list" xs={3}>
          <b className="me-2">{t("manage")}</b>
        </Col>
      </Row>
    </div>
  );
};

export default memo(HeaderTitle);
