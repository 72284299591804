import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import "./styles/index.scss";

import Layout from "components/Layouts";
import Login from "features/auths/Login";

// :: import Main Layout For Authentications :: //
import MainLayouts from "./layouts/MainLayouts";
import RequireAuth from "./features/auths/RequireAuth";

function App({ t }) {
  return (
    <Routes>
      <Route path="/login" element={<Layout />}>
        {/** Public Routes */}
        <Route index element={<Login />} />
      </Route>

      {/** Private Routes */}
      <Route element={<RequireAuth />}>
        <Route path="*" element={<MainLayouts translates={t} />} />
      </Route>
    </Routes>
  );
}

export const checkAuthentication = () => {
  // alert("Hello Check Authen");
  const authens = localStorage.getItem("Autorization");
  if (!authens) {
    return true;
  } else {
    return false;
  }
};

export default withNamespaces()(App);
