import React, { memo, useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Gauge } from "@ant-design/plots";

const AntGuage = () => {
  const config = {
    percent: Math.random(0, 1),
    range: {
      ticks: [0, 1 / 3, 2 / 3, 1],
      color: ["#ea1212", "#f9c10a", "#0eec72"],
    },
    indicator: {
      pointer: {
        style: {
          stroke: "#989898",
        },
      },
      pin: {
        style: {
          stroke: "#989898",
        },
      },
    },
    statistic: {
      content: {
        style: {
          fontSize: "1rem",
          lineHeight: "24px",
          color: "#989898",
        },
      },
    },
  };
  return (
    <Card className="p-3 mb-3 dark-gray  w-100">
      <div className="text-center">
        <Gauge {...config} />
      </div>
    </Card>
  );
};

export default memo(AntGuage);
