import { memo } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { HelpCircle } from "react-feather";

const CardComponents = ({ icon, title, desc, value, unit, color }) => {
  return (
    <div>
      <Card className={`bg-transparent bd-${color ?? "orange"} px-4 mb-3`}>
        <Row>
          <Col xs={4} sm={4} className={`data icon ${color}`}>
            {icon ? icon : <HelpCircle size={40} />}
          </Col>
          <Col xs={8} sm={8}>
            <Row>
              <Col sm={12}>
                <h3 className="data title">{`${title ?? "ENTER TITLE"}`}</h3>
              </Col>
              <Col sm={12}>
                <p className="data description">{`${
                  desc ?? "ENTER DESCRIPTION"
                }`}</p>
              </Col>
              <Col sm={12} className="text-end">
                <h1 className={`data value ${color}`}>
                  {`${value ?? "000"}`}{" "}
                  <i className="data unit">{`${unit ?? ""}`}</i>
                </h1>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default memo(CardComponents);
