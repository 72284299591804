import React from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import commaNumber from "comma-number";

export const StatusBounce = (value) => {
  return (
    <div className="blink-me ">
      {value.status === "D" && (
        <>
          <div className="dot danger"></div>
          <small className="mini-mobile ms-1 text-danger">ALERT</small>
        </>
      )}

      {value.status === "W" && (
        <>
          <div className="dot warning"></div>
          <small className="mini-mobile ms-1 text-warning">WARNING</small>
        </>
      )}

      {value.status === "N" && (
        <>
          <div className="dot success"></div>
          <small className="mini-mobile ms-1 text-success">NORMAL</small>
        </>
      )}
    </div>
  );
};

const Detections = ({ data }) => {
  return (
    <div className="detections ">
      <Row
        className="p-3 scroller-y"
        style={{ fontSize: "small" }}>
        {data?.map((res, k) => {
          return (
            <Col
              className="p-3 card-detection"
              key={k}
              xs={12}>
              <Row>
                <Col
                  xs={6}
                  md={1}>
                  <div>
                    <small className="mini-mobile text-secondary">STATUS</small>
                  </div>
                  <StatusBounce status={res?.ticketStatus} />
                </Col>
                <Col
                  xs={6}
                  md={2}>
                  <div>
                    <small className="mini-mobile text-secondary">
                      STRING NAME
                    </small>
                  </div>
                  {res?.stringName}
                </Col>
                <Col
                  xs={6}
                  md={2}>
                  <div>
                    <small className="mini-mobile text-secondary">
                      LOSS VALUE
                    </small>
                  </div>

                  {commaNumber(parseFloat(res?.valueLoss).toFixed(2) ?? 0)}
                </Col>
                <Col
                  xs={6}
                  md={1}>
                  <div>
                    <small className="mini-mobile text-secondary">POWER</small>
                  </div>
                  {res?.p}
                </Col>
                <Col
                  xs={6}
                  md={1}>
                  <div>
                    <small className="mini-mobile text-secondary">PR</small>
                  </div>
                  {res?.pr}
                </Col>
                <Col
                  xs={6}
                  md={1}>
                  <div>
                    <small className="mini-mobile text-secondary">IRR</small>
                  </div>
                  {res?.irr}
                </Col>
                <Col
                  xs={6}
                  md={1}>
                  <div>
                    <small className="mini-mobile text-secondary">
                      AMB TEMP
                    </small>
                  </div>
                  {res?.ambTemp}
                </Col>
                <Col
                  xs={6}
                  md={1}>
                  <div>
                    <small className="mini-mobile text-secondary">
                      PV TEMP
                    </small>
                  </div>
                  {res?.pvTemp}
                </Col>
                <Col
                  xs={6}
                  md={2}>
                  <div>
                    <small className="mini-mobile text-secondary">
                      DATE STAMP
                    </small>
                  </div>
                  {moment(res?.stampDate).format("DD/MM/YYYY")}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Detections;
