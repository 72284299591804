import { memo, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import commaNumber from "comma-number";

const CardPayments = ({ valueMax, valueMin, onFinish, discount }) => {
  const [validated, setValidated] = useState(false);
  const [checkedType, setCheckedType] = useState(undefined);
  const [message, setMessage] = useState(false);
  const [disable, setDisable] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!checkedType) {
      setMessage(true);
      return false;
    }
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const formData = new FormData(event.target);
      const formDataObj = Object.fromEntries(formData.entries());
      const data = {
        ...formDataObj,
        type: checkedType,
      };

      onFinish({ ...data });

      setDisable(true);
    }

    setValidated(true);
  };

  const handleChangeType = (value) => {
    setMessage(false);
    setCheckedType(value);
    setDisable(false);
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="card bor-dark-gray mb-2">
          <Row>
            <Col xs={1} className="text-center">
              <Form.Check // prettier-ignore
                className="checkbox-customs mt-1"
                type={`radio`}
                id={`default-checkbox`}
                name="payment"
                value={(valueMin * discount).toFixed(2) ?? 0}
                onChange={() => handleChangeType("P")}
              />
            </Col>
            <Col xs={6}>
              <h3 className="mb-0">Partial Payment</h3>
              <p className="text-secondary mb-0">
                Choose an item to pay in small.
              </p>
            </Col>
            <Col xs={5} className="text-end">
              <h4 className="nondiscount-price text-danger text-end mb-0">
                {commaNumber(valueMin) ?? 0} ฿
              </h4>
              <h3 className="text-warning">
                {commaNumber((valueMin * discount).toFixed(2))} ฿
              </h3>
            </Col>
          </Row>
        </div>
        <div className="card bor-dark-gray mb-2">
          <Row>
            <Col xs={1} className="text-center">
              <Form.Check // prettier-ignore
                className="checkbox-customs mt-1"
                type={`radio`}
                id={`default-checkbox`}
                name="payment"
                value={(valueMax * discount).toFixed(2) ?? 0}
                onChange={() => handleChangeType("F")}
              />
            </Col>
            <Col xs={6}>
              <h3 className="mb-0">Full Payment</h3>
              <p className="text-secondary mb-0">
                Choose an item to pay in full.
              </p>
            </Col>
            <Col xs={5}>
              <h4 className="nondiscount-price text-danger text-end mb-0">
                {commaNumber(valueMax.toFixed(2)) ?? 0} ฿
              </h4>
              <h3 className="text-warning text-end">
                {commaNumber((valueMax * discount).toFixed(2))} ฿
              </h3>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col xs={12}>
              {message && (
                <p className="text-danger">Please select payment method.</p>
              )}
            </Col>
            <Col xs={12} className="text-end">
              <Button
                variant="outline-primary"
                size="sm"
                style={{ width: 120 }}
                type="submit"
                disabled={disable}
              >
                Payment
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default memo(CardPayments);
