import { memo } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import ProfileIconDefault from "assets/images/defaults/user.png";

const Welcomes = ({ data, plant, t }) => {
  return (
    <div className="card-revenues">
      <Row>
        <Col xs={12} className="mb-3">
          <h3 className="title-up">{t("your_profile")}</h3>
          <label>
            {t("welcome_customer")}
            {` `}
            <Link>
              {data?.firstName ?? ""}
              {data?.middleName ? ` ${data?.middleName}` : " "}
              {data?.lastName ? ` ${data?.lastName}` : " "}
            </Link>
            {` `}
            {t("to_program")} Esma
          </label>
        </Col>
        <Col xs={3} className="text-center mt-2">
          <Link
            className="user-profile-icon-big"
            style={{
              backgroundImage: `url(${
                data?.profileImage ?? ProfileIconDefault
              })`,
            }}
          ></Link>
        </Col>
        <Col xs={9} className="mb-3">
          <div>
            <h4 className="mb-1">
              {data?.firstName ?? ""}
              {data?.middleName ? ` ${data?.middleName}` : " "}
              {data?.lastName ? ` ${data?.lastName}` : " "}
            </h4>
          </div>
          <div>
            <p className="mb-1">{data?.email ?? ""}</p>
          </div>
          <div>
            <p className="mb-1">{data?.phoneNumber ?? ""}</p>
          </div>
        </Col>
        <Col xs={12}>
          <h4>{t("asset_plant")}</h4>
          <p className="mb-1">{plant?.plantName ?? ""}</p>
        </Col>
      </Row>
    </div>
  );
};

export default memo(Welcomes);
